import React from 'react';
import { withPreview } from 'gatsby-source-prismic';
import Layout from '../components/layout';
import HeroSection from '../components/HeroSection';
import Description from '../components/pages/home/Description';
import ImageGallery from '../components/slices/pages/ImageGallery';
import RelatedProjects from '../components/globals/RelatedProjects';
import Form from '../components/globals/Form';
import { color } from '../shared/styles';

export const data = graphql`
  {
    prismicPageTemplate(uid: { eq: "home" }) {
      data {
        body {
          ... on PrismicPageTemplateBodyImageGallery {
            id
            slice_type
            slice_label
            primary {
              link {
                uid
              }
              description_of_the_gallery {
                html
                text
              }
              name_of_the_gallery {
                html
                text
              }
            }
            items {
              image {
                url
              }
              image_captions {
                html
                text
              }
            }
          }
        }

        page_header {
          html
          text
        }
        page_title {
          html
          text
        }
        page_subheader {
          text
          html
        }
        hero_image {
          url
        }
        hero_background_video {
          url
        }
      }
      uid
    }
    allPrismicProjectTemplate {
      nodes {
        data {
          project_title {
            text
            html
          }
          related_image {
            url
          }
        }
        uid
      }
    }
    prismicContactInformation {
      data {
        contact_information_section {
          section_text {
            html
            text
          }
        }
      }
    }
  }
`;

const index = ({ data }) => {
  const cms = data.prismicPageTemplate.data;
  const projects = data.allPrismicProjectTemplate.nodes;
  const contact = data.prismicContactInformation.data.contact_information_section;
  // const contact = data.prismicContactInformation.data.contact_information_section;
  return (
    <Layout bgColor={color.mainBg}>
      <HeroSection header={cms.page_header.html} bgVideo={cms.hero_background_video.url} />
      <Description html={cms.page_subheader.html} />
      {cms.body.map((gallery, i) =>
        gallery.slice_type === 'image_gallery' ? (
          <ImageGallery
            link={gallery.primary.link.uid}
            title={gallery.primary.name_of_the_gallery.text}
            description={gallery.primary.description_of_the_gallery.html}
            images={gallery.items}
            index={i}
            name={gallery.primary.name_of_the_gallery.text}
          />
        ) : (
          ''
        ),
      )}
      <RelatedProjects items={projects} pages />
      <Form cms={contact} />
    </Layout>
  );
};

export default withPreview(index);
